<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event" />

      <div v-if="event">
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-alert tile v-if="!event.verification" type="warning">This event does not require verification codes, anyone can join. Click Edit to change this setting.</v-alert>
        <v-card-title class="subtitle pt-4">Manage blocked verification codes</v-card-title>
        <v-card-text>      
          <p>
            The codes below have been used as verification codes for this event.
          </p>

          <p>
            <v-btn color="primary" @click="releaseCode">Release a code</v-btn>
          </p>

          <v-alert v-if="!codes" type="info">Empty blocklist. No verification codes have been used (yet).</v-alert>
          <pre>{{codes}}</pre>
        </v-card-text>

      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'

export default {
  name: "Profile",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      codes: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;

      this.codes = (await eventManagerService.getDenylist(id)).data.msg;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async releaseCode() {
      var code = prompt('Enter the code you want to release:');
      if (code) {
        const response = (await eventManagerService.releaseCode(this.event.id, code)).data;
        console.log(response);
        await this.getProfile();
      }
    }

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Blocklist', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

